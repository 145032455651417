.toolbar-container {
  position: sticky;
  top: 0;
  z-index: 1; /* Ensure toolbar is above content */
  background-color: #fff; /* Set toolbar background color */
}

.ql-editor {
  min-height: 124px !important;
  overflow-y: auto !important;
  max-height: 300px !important;
}
.animate-popover {
  animation: popOver 200ms linear;
}

@keyframes popOver {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

 .active_status svg  path{
  fill: #031b59 !important;
}
.ql-snow .ql-tooltip  {
  padding: 4px 80px;
  position: static;
  transform: none;
}